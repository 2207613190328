import screen1 from '../screensProjects/imagesProjects/screen1.jpg'
import screen2 from '../screensProjects/imagesProjects/screen2.jpg'
import screen3 from '../screensProjects/imagesProjects/screen3.jpg'
import screen4 from '../screensProjects/imagesProjects/screen4.jpg'

export const screensProjects = [
  {
    src: screen1,
    width: 5,
    height: 3
  },
  {
    src: screen2,
    width: 5,
    height: 3
  },
  {
    src: screen3,
    width: 5,
    height: 3
  },
  {
    src: screen4,
    width: 5,
    height: 3
  },
];
