import React, {useState} from 'react';
import {Sidebar} from "primereact/sidebar";
import styles from './Myheader.module.scss';
import imageImpltech from '../../images/impltech.jpg';
import imageWheels from '../../images/wheelsop.jpg';
import imageAgro from '../../images/agrorprom.jpg';
import imageDeepDive from '../../images/deepdive.jpeg';
import imageCapgemini from '../../images/capgemini.png';
import imageLinkedIn from '../../images/linkedIn.png';
import imageGithub from '../../images/gitlarge.png';
import {Accordion, AccordionTab} from "primereact/accordion";
import {Panel} from "primereact/panel";
import {ScrollPanel} from "primereact/scrollpanel";

const MyHeader = () => {
  const [state, setState] = useState({
    visibleMenu: false,
  });

  const openHideMenu = () => {
    setState({...state, visibleMenu: !state.visibleMenu});
  };

  return (
    <header>
      {!state.visibleMenu &&
      <div className={styles.hamburgerButton}>
        <div className={styles.iconHamburger}>
          <i className="pi pi-align-justify"
             style={{'fontSize': '2em'}}
             onClick={() => openHideMenu()}/>
        </div>
      </div>
      }

      <Sidebar
        visible={state.visibleMenu} position="right"
        onHide={() => openHideMenu()}
        iconsTemplate={() => (
          <React.Fragment>
            <button className="p-sidebar-close p-link">
            </button>
          </React.Fragment>
        )}>
        <Panel header="About me" className={styles.sidebar}>
          <Accordion>
            <AccordionTab header="Education">

              <Panel header="Taras Shevchenko National University of Kyiv. INSTITUTE OF INTERNATIONAL RELATIONS.">
                <p>Analyst.</p>
                <p>Bachelor (4.5 years)</p>
              </Panel>
            </AccordionTab>
            <AccordionTab header="Experience">
              <ScrollPanel style={{width: '100%', height: '300px'}}>
                <Panel header="Capgemini Engineering" className={styles.card}>
                  <p className={styles.year}>
                    October 2021 till now
                  </p>
                  <p>
                    Back End engineer
                  </p>
                  <p>
                    <a href="https://capgemini-engineering.com.ua/">
                      <img src={imageCapgemini} alt={imageDeepDive} width={100} height={100} className={styles.image}/>
                    </a>
                  </p>
                </Panel>
                <Panel header="Deep Dive Tech" className={styles.card}>
                  <p className={styles.year}>
                    December 2020 - October 2021
                  </p>
                  <p>
                    Back End engineer
                  </p>
                  <p>
                    <a href="https://www.deepdive.tech/">
                      <img src={imageDeepDive} alt={imageDeepDive} width={100} height={100} className={styles.image}/>
                    </a>
                  </p>
                </Panel>
                <Panel header="Impltech" className={styles.card}>
                  <p className={styles.year}>
                    2020
                  </p>
                  <p>
                    Fullstack Developer
                  </p>
                  <p>
                    <a href="https://impltech.de/">
                      <img src={imageImpltech} alt={imageImpltech} width={145} height={29} className={styles.image}/>
                    </a>
                  </p>
                  <p className={styles.description}>1 year</p>
                </Panel>
                <Panel header="Wheelshop" className={styles.card}>
                  <p className={styles.year}>
                    2018
                  </p>
                  <p>
                    Analyst and project manager. And if in general as a universal soldier.
                    It was then that I became interested in programming and developed IOS app
                    for employer.
                  </p>
                  <p>
                    <a href="https://www.wheelshop.com.ua//">
                      <img src={imageWheels} alt={imageWheels} width={100} height={100} className={styles.image}/>
                    </a>
                  </p>
                  <p className={styles.description}>In general 6 month:</p>
                  <p>~2 years</p>
                </Panel>
                <Panel header="In Agropromservis" className={styles.card}>
                  <p className={styles.year}>
                    2017
                  </p>
                  <p>
                    Analyst.
                  </p>
                  <p>
                    <a href="http://agropromservice.com.ua/">
                      <img src={imageAgro} alt={imageAgro} width={100} height={100} className={styles.image}/>
                    </a>
                  </p>
                  <p className={styles.description}>~1 year</p>
                </Panel>
              </ScrollPanel>
            </AccordionTab>
            <AccordionTab header="My socials">
              <ScrollPanel style={{width: '100%', height: '300px'}}>
                <Panel header="GitHub" className={styles.card}>
                  <p>
                    <a href="https://github.com/karpyshynroman?tab=repositories">
                      <img src={imageGithub} alt={imageGithub} width={100} height={100} className={styles.image}/>
                    </a>
                  </p>
                </Panel>
                <Panel header="LinkedIn" className={styles.card}>
                  <p>
                    <a href="https://www.linkedin.com/in/roman-k-9a291220b/">
                      <img src={imageLinkedIn} alt={imageLinkedIn} width={100} height={100} className={styles.image}/>
                    </a>
                  </p>
                </Panel>
                <Panel header="Phone">
                  <p>+38 (093) 016 99 58</p>
                </Panel>
              </ScrollPanel>
            </AccordionTab>
          </Accordion>
        </Panel>
      </Sidebar>
    </header>
  )
    ;
};

export default MyHeader;
