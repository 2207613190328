import React, {useState} from 'react';
import './App.scss';
import MyHeader from "./components/Header/MyHeader";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Portfolio from "./components/Portfolio/Portfolio";

function App() {
  return (
    <div className="App">
      <MyHeader/>
      <Portfolio/>
    </div>
  );
}

export default App;
