import React, {useCallback, useEffect, useState} from 'react';
import Gallery from "react-photo-gallery";
import Carousel, {Modal, ModalGateway} from "react-images";
import {Card} from "primereact/card";
import styles from "./ScreenGallery.module.scss";

const ScreenGallery = ({images}) => {
  const [state, setState] = useState({
    images: [],
  });
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, {photo, index}) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };


  useEffect(() => {
    setState({
      ...state,
      images
    })
  }, []);


  return (
    <div className={styles.wrapper}>
      <Card>
        <Gallery photos={state.images} onClick={openLightbox}/>
        <ModalGateway>
          {viewerIsOpen && (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={state.images.map(item => ({
                  ...item,
                  srcset: item.srcSet,
                  caption: item.title
                }))}
              />
            </Modal>
          )}
        </ModalGateway>
      </Card>
    </div>
  );
};

export default ScreenGallery;
