import React, {useEffect, useState} from 'react';
import styles from "./Porfolio.module.scss";
import {Panel} from "primereact/panel";
import imageReact from '../../images/react.png';
import imageJs from '../../images/js.png';
import imageHtml from '../../images/html.png';
import imageRedux from '../../images/redux.png';
import imageBootstrap from '../../images/bootstrap.png';
import imageSemantic from '../../images/semantic.png';
import imageSass from '../../images/sass.png';
import imageCss from '../../images/css.png';
import imageBem from '../../images/bem.png';
import imageJquery from '../../images/jquery.png';
import imagePrimeReact from '../../images/primereact.png';
import imageGithub from '../../images/Github.png';
import imageInternet from '../../images/internet.png';
import imageNode from '../../images/node.png';
import imagePostgres from '../../images/postgres.jpg';
import imageMysql from '../../images/mysql.jpg';
import imageExpress from '../../images/express.jpg';
import imageNest from '../../images/nest.png';
import imageSocket from '../../images/soket.png';
import imageSwift from '../../images/swift.png';
import imageDocker from '../../images/docker.jpg';
import imageDigitalOcean from '../../images/ocean.png';
import imageMogoDb from '../../images/mongo.png';
import imageTradeHub from '../../images/tradehub.jpg';
import imageWheelshop from '../../images/wheelsop.jpg';
import imageApolloGraph from '../../images/graph-apollo.png';
import imageNextJs from '../../images/nextjs.png';
import imageAws from '../../images/aws.png';
import imageReactNative from '../../images/reactnative.png';
import imageTypeScript from '../../images/typescript.png';
import imageAlladinWallet from '../../images/alladinwallet.jpg';
import imageAlladinPro from '../../images/alladinpro.jpg';
import imageEOS from '../../images/eos.jpg';
import imageBitcoin from '../../images/Bitcoin.jpg';
import imageBSV from '../../images/bsv.png';
import imageLtc from '../../images/ltc.png';
import imageRvn from '../../images/ravencoin-rvn-logo.jpg';
import imageEth from '../../images/etherum.png';
import imageHeroku from '../../images/heroku.jpg';
import imageEricsson from '../../images/ericsson.png';
import imageVirgin from '../../images/virgin-logo.jpg';
import imageKubernetes from '../../images/kubernetes.png';
import imageRabbitmq from '../../images/rabbitmq.png';
import imageKafka from '../../images/kafka.png';
import imageRedis from '../../images/redis.png';
import {Accordion, AccordionTab} from "primereact/accordion";
import {Button} from "primereact/button";
import {screensProjects} from "../../images/screensProjects/projects";
import ScreenGallery from "../ScreenGallery/ScreenGallery";
import {TabMenu} from "primereact/tabmenu";

const Portfolio = () => {

  const [state, setState] = useState({
    directions: [],
    activeItem: [],
    projectType: ''
  });

  const data = {
    directions: [
      {
        label: 'Front',
        technologies:
          [
            {name: 'JS', image: imageJs},
            {name: 'HTML', image: imageHtml},
            {name: 'CSS3', image: imageCss},
            {name: 'jQuery', image: imageJquery},
            {name: 'BEM', image: imageBem},
            {name: 'Sass', image: imageSass},
            {name: 'Bootstrap', image: imageBootstrap},
            {name: 'SemanticUI', image: imageSemantic},
            {name: 'PrimeReact', image: imagePrimeReact},
            {name: 'React', image: imageReact},
            {name: 'ReactNative', image: imageReactNative},
            {name: 'Redux', image: imageRedux},
            {name: 'TypeScript', image: imageTypeScript},
            {name: 'NextJS', image: imageNextJs}
          ],
        projects:
          [
            {
              training:
                [
                  {
                    name: 'Todo App',
                    description: 'React based',
                    urlPage: 'https://karpyshynroman.github.io/react_todo-app/',
                    urlCode: 'https://github.com/karpyshynroman/react_todo-app/tree/fs_aug19_roma',
                  },
                  {
                    name: 'Miami',
                    description: 'Landing page',
                    urlPage: 'https://karpyshynroman.github.io/layout_miami2/src/index.html',
                    urlCode: 'https://github.com/karpyshynroman/layout_miami2',
                  },
                  {
                    name: 'Dia',
                    description: 'Landing page',
                    urlPage: 'https://karpyshynroman.github.io/dia/index.html',
                    urlCode: 'https://github.com/karpyshynroman/dia',
                  }
                ],
              commercial:
                [
                  {
                    name: 'Kahlenberg',
                    urlPage: '',
                    description: 'This is interior project for the Polish Football Association to provide insurance to football players.' +
                      'And so below are just screenshots, without links',
                  }
                ]
            }
          ]
      },
      {
        label: 'Back',
        technologies:
          [
            {name: 'NodeJS', image: imageNode},
            {name: 'TypeScript', image: imageTypeScript},
            {name: 'PostgresSQL', image: imagePostgres},
            {name: 'MySQL', image: imageMysql},
            {name: 'MongoDB', image: imageMogoDb},
            {name: 'ExpressJS', image: imageExpress},
            {name: 'NestJS', image: imageNest},
            {name: 'GraphQL+Apollo', image: imageApolloGraph},
            {name: 'SocketIO', image: imageSocket},
            {name: 'RabbitMQ', image: imageRabbitmq},
            {name: 'Kafka', image: imageKafka},
            {name: 'Redis', image: imageRedis},
          ],
        projects:
          [
            {
              commercial:
                [
                  {
                    name: 'Virgin group', description: 'Virgin voyages',
                    urlPage: 'https://www.virginvoyages.com/',
                    imageProject: imageVirgin,
                  },
                  {
                    name: 'Ericsson', description: 'Interior market place for telecommunications equipment tenders',
                    urlPage: 'https://www.ericsson.com',
                    imageProject: imageEricsson,
                  },
                  {
                    name: 'Aladdin Pro', description: 'Integration crypto currencies: EOS, LTC, BSV, BTC, RVN, ETH',
                    urlPage: 'https://play.google.com/store/apps/details?id=com.abbc.aladdin.pro',
                    imageProject: imageAlladinPro,
                  },
                  {
                    name: 'Aladdin Wallet', description: 'Integration crypto currencies: EOS, LTC, BSV, BTC, RVN, ETH',
                    urlPage: 'https://play.google.com/store/apps/details?id=com.abbc.abbcmobile',
                    imageProject: imageAlladinWallet,
                  },
                  {
                    name: 'TradeHUB', description: 'API for the tender platform',
                    urlPage: 'https://tradehub.ua/',
                    imageProject: imageTradeHub
                  },
                ]
            }
          ]
      },
      {
        label: 'Mobile',
        technologies:
          [
            {name: 'Swift', image: imageSwift},
          ],
        projects:
          [
            {
              commercial:
                [
                  {
                    name: 'Wheelshop',
                    description: 'IOS app store',
                    urlPage: 'https://apps.apple.com/ua/app/wheelshop/id1482952026',
                    imageProject: imageWheelshop
                  }
                ]
            }
          ]
      },
      {
        label: 'DevOps',
        technologies:
          [
            {name: 'DigitalOcean', image: imageDigitalOcean},
            {name: 'AWS', image: imageAws},
            {name: 'Heroku', image: imageHeroku},
            {name: 'Docker', image: imageDocker},
            {name: 'Kubernetes', image: imageKubernetes},
          ],
      },
      {
        label: 'BlockChain',
        technologies:
          [
            {name: 'EOS', image: imageEOS},
            {name: 'Bitcoin', image: imageBitcoin},
            {name: 'Bitcoin-SV', image: imageBSV},
            {name: 'Litecoin', image: imageLtc},
            {name: 'Ravencoin', image: imageRvn},
            {name: 'Etherum', image: imageEth},
          ],
      },
    ]
  };

  useEffect(() => {
    setState({
      ...state,
      ...data,
      activeItem: data.directions[0],
    })
  }, []);

  const setActiveItem = (e) => {
    setState({
      ...state,
      activeItem: e.value
    })
  };

  return (
    <>
      <TabMenu model={state.directions} activeItem={state.activeItem}
               onTabChange={(e) => setActiveItem(e)}/>
      {state.activeItem.label === 'BlockChain' &&
      <Panel header={'Coins integration'} className={styles.cardProjectExplanation}>
        Full cycle: nodes deployment, purchase and exchange currencies in wallet service.
      </Panel>
      }
      <div className={styles.cardWrapper}>
        {state.activeItem.technologies &&
        state.activeItem.technologies.map(item =>
          <Panel header={item.name} className={styles.card} key={Math.random()}>
            <img src={item.image} alt={item.name} width={100} height={100}/>
          </Panel>)
        }
      </div>
      {state.activeItem.projects &&
      <Panel header={'Projects'} className={styles.cardProject}>
        {state.activeItem.projects[0].training &&
        <Accordion>
          <AccordionTab header="Training">
            <div className={styles.cardWrapper}>
              {state.activeItem.projects[0].training.map(item =>
                <Panel header={item.name} className={styles.card} key={Math.random()}>
                  <p className={styles.description}>{item.description}</p>
                  <div className={styles.gitWrapper}>
                    <img src={imageGithub} alt={imageGithub} width={35} height={35}/>
                    <form action={item.urlCode}>
                      <Button label="Code" className="p-button-secondary p-button-rounded"/>
                    </form>
                  </div>
                  <div className={styles.gitWrapper}>
                    <img src={imageInternet} alt={imageGithub} width={35} height={35}/>
                    <form action={item.urlPage}>
                      <Button label="Page" className="p-button-secondary p-button-rounded"/>
                    </form>
                  </div>
                </Panel>)
              }
            </div>
          </AccordionTab>
        </Accordion>
        }
        {state.activeItem.projects[0].commercial &&
        <Accordion>
          <AccordionTab header="Commercial">
            {state.activeItem.projects[0].commercial.map(item =>
              <div className={item.urlPage && styles.cardWrapper}>
                <div className={item.urlPage && styles.card}>
                  <Panel header={item.name} key={Math.random()}>
                    <p className={styles.description}>{item.description}</p>
                    {item.urlPage
                      ?
                      <a href={item.urlPage}>
                        <img src={item.imageProject} alt={item.imageProject} width={100} height={100}/>
                      </a>
                      :
                      <ScreenGallery images={screensProjects}/>
                    }
                  </Panel>
                </div>
              </div>)
            }
          </AccordionTab>
        </Accordion>
        }
      </Panel>
      }
    </>
  );
};

export default Portfolio;
